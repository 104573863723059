import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Table, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import FaceDataCollection from './FaceDataCollection';
import FaceRecognitionButton from '../CustomComponent/FaceRecognitionButton';

function Classroom() {
  const location = useLocation();
  const classData = location.state;

  const [attendanceData, setAttendanceData] = useState([]);
  const [students, setStudents] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [sessionStartTimes, setSessionStartTimes] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showFaceModal, setShowFaceModal] = useState(false); // Manage Face Modal visibility
  const [selectedDate, setSelectedDate] = useState('');
  const [attendanceState, setAttendanceState] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAttendanceData = async () => {
      setLoading(true);
      try {
        const response = await axios.get('http://139.59.110.50/api/attendance/', {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
          params: { classroom: classData.id },
        });

        const data = response.data;

        if (Array.isArray(data.students) && Array.isArray(data.dates)) {
          setAttendanceData(data);
          setStudents(data.students);
          setSessions(data.dates);
          initializeAttendanceState(data.students, data.dates, data);
        } else {
          console.error('Invalid data format:', data);
        }
      } catch (error) {
        console.error('Error fetching attendance data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAttendanceData();
  }, [classData.id]);

  const initializeAttendanceState = (students, sessions, data) => {
    const initialState = {};
    students.forEach(student => {
      initialState[student.id_number] = {};
      sessions.forEach(session => {
        const status = data.students
          .find(studentData => studentData.id_number === student.id_number)
          ?.attendance?.[session] || "Absent";
        initialState[student.id_number][session] = status;
      });
    });
    setAttendanceState(initialState);
    console.log('Attendance state initialized:', initialState);
  };

  const handleNewSession = () => {
    setShowModal(true);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleSubmitDate = async () => {
    if (!selectedDate) {
      alert('Please select a date');
      return;
    }

    try {
      const response = await axios.post(
        'http://139.59.110.50/api/create-session/',
        {
          classroom_id: classData.id,
          date: selectedDate,
          attendance: students.map(student => ({
            student_id: student.id_number,
            status: "Absent",
          })),
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }
      );

      if (response.status === 201) {
        setSessions(prevSessions => [...prevSessions, selectedDate].sort((a, b) => new Date(a) - new Date(b)));

        setSessionStartTimes(prev => ({
          ...prev,
          [selectedDate]: new Date().toISOString(), // Store session start time
        }));

        setAttendanceState(prevState => {
          const updatedState = { ...prevState };
          students.forEach(student => {
            updatedState[student.id_number] = {
              ...updatedState[student.id_number],
              [selectedDate]: "Absent",
            };
          });
          return updatedState;
        });

        setShowModal(false);
      }
    } catch (error) {
      console.error('Error creating session:', error);
      alert('Failed to create a new session. Please try again.');
    }
  };

  const handleAttendanceCheckboxChange = async (studentID, sessionDate, isChecked) => {
    const currentTime = new Date();
    const sessionStartTime = new Date(sessionStartTimes[sessionDate]); // Get session start time
    const timeDifference = (currentTime - sessionStartTime) / 60000; // Convert to minutes

    let status = "Absent";
    if (isChecked) {
      status = timeDifference <= 30 ? "Present" : "Late";
    }

    setAttendanceState(prevState => {
      return {
        ...prevState,
        [studentID]: {
          ...prevState[studentID],
          [sessionDate]: status,
        },
      };
    });

    const payload = {
      student: studentID,
      classroom: classData.id,
      date: sessionDate,
      status,
    };

    console.log('Payload sent to backend:', payload);

    try {
      const response = await axios.post('http://139.59.110.50/api/save-attendance/', payload, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });

      if (response.status === 200) {
        console.log('Attendance status updated successfully.');
      }
    } catch (error) {
      console.error('Error updating attendance status:', error);
      alert('Failed to update attendance status. Please try again.');
    }
  };

  const navigate = useNavigate();

  const handleFaceDataCapture = () => {
    navigate("/face-data-collection", { state: classData });
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>{classData?.course_title || "Loading..."}</h2>
      <p><strong>Course Code:</strong> {classData?.course_code || "Loading..."}</p>
      <p><strong>Section:</strong> {classData?.section_code || "Loading..."}</p>
      <p><strong>Schedule:</strong> {classData?.schedule || "Loading..."}</p>

      <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
        <Button variant="primary" onClick={handleNewSession}>New Session</Button>
        <Button variant="secondary" onClick={handleFaceDataCapture}>Add Facial Data</Button>
        <FaceRecognitionButton 
          classroomId={classData.id} 
          sessionStartTimes={sessionStartTimes} 
          updateAttendance={handleAttendanceCheckboxChange}
        />
      </div>

      {loading ? (
        <div>Loading...</div>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th className="text-center">ID Number</th>
              <th className="text-center">Lastname</th>
              <th className="text-center">Firstname</th>
              {sessions?.map((session, index) => (
                <th key={index} className="text-center">{session}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {students?.map((student, index) => (
              <tr key={index}>
                <td className="text-center">{student.id_number}</td>
                <td className="text-center">{student.surname}</td>
                <td className="text-center">{student.fname}</td>
                {sessions?.map((session, idx) => (
                  <td key={idx} className="text-center">
                    <input
                      type="checkbox"
                      checked={attendanceState[student.id_number]?.[session] !== "Absent"}
                      onChange={(e) => handleAttendanceCheckboxChange(student.id_number, session, e.target.checked)}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select Date for New Session</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formBasicDate">
            <Form.Control type="date" value={selectedDate} onChange={handleDateChange} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
          <Button variant="primary" onClick={handleSubmitDate}>Add Session</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Classroom;
