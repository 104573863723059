import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Row, Col, Card, Stack } from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import CustomContextMenu from '../utils/CustomContextMenu';
import axios from 'axios';
import Notification from '../CustomComponent/Notification';

export default function Dashboard() {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    courseCode: '',
    courseTitle: '',
    section: '',
    schedule: '',
    schoolYear: '',
    yearLevel: '',
    semester: '',
    file: null,
  });
  const [classes, setClasses] = useState([]);
  const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, classId: null });
  
  // States to store dynamic year levels and semesters
  const [yearLevels, setYearLevels] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [schoolYears, setSchoolYears] = useState([]);
  const navigate = useNavigate();

  // States fo the filters
  const [selectedYearLevel, setSelectedYearLevel] = useState('');
  const [selectedSemester, setSelectedSemester] = useState('');
  const [selectedSchoolYear, setSelectedSchoolYear] = useState('');

  // Function to refresh the access token using the refresh token
  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refresh_token');
    
    if (!refreshToken) {
      console.error('No refresh token found.');
      return null;
    }

    try {
      const response = await axios.post('http://139.59.110.50/api/auth/jwt/refresh/', {
        refresh: refreshToken,
      });

      const { access_token, refresh_token } = response.data;

      // Store the new access token and refresh token in local storage
      localStorage.setItem('access_token', access_token);
      if (refresh_token) {
        localStorage.setItem('refresh_token', refresh_token);  // Update refresh token if provided
      }

      return access_token;  // Return the new access token
    } catch (error) {
      console.error('Failed to refresh access token:', error);
      return null;  // Return null if refresh fails
    }
  };
  
  // Fetch classrooms function
  const fetchClassrooms = async () => {
    let accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      console.error('Access token is missing.');
      return;
    }
  
    const params = {};
    if (selectedSchoolYear) params.school_year = selectedSchoolYear;  // Add school year filter
    if (selectedYearLevel) params.year_level = selectedYearLevel;  // Add year level filter
    if (selectedSemester) params.semester = selectedSemester;  // Add semester filter
  
    try {
      // Fetch classrooms from the API with filters
      const classroomsResponse = await axios.get('http://139.59.110.50/api/classrooms/', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: params,  // Pass filters as query parameters
      });
  
      setClasses(classroomsResponse.data);  // Set the fetched classrooms data
    } catch (error) {
      console.error('Error fetching classrooms:', error);
    }
  };  

  // Fetch School Year, Year Levels, and Semesters from the API
  useEffect(() => {
    const fetchData = async () => {
      let accessToken = localStorage.getItem('access_token');
      if (!accessToken) {
        console.error('Access token is missing.');
        return;
      }

      try {
        // Fetch Year Levels
        const yearLevelsResponse = await axios.get('http://139.59.110.50/api/year-levels/', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        // Fetch Semesters
        const semestersResponse = await axios.get('http://139.59.110.50/api/semesters/', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        // Fetch School Years
        const schoolYearsResponse = await axios.get('http://139.59.110.50/api/school-years/', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        // Set Year Levels, Semesters, and School Years
        setYearLevels(yearLevelsResponse.data);
        setSemesters(semestersResponse.data);
        setSchoolYears(schoolYearsResponse.data);  // Store school years

        await fetchClassrooms(); // Fetch classrooms here

      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log('Access token expired, attempting to refresh...');
          accessToken = await refreshAccessToken();  // Try to refresh the access token

          if (accessToken) {
            // Retry the original request after refreshing the token
            try {
              const yearLevelsResponse = await axios.get('http://139.59.110.50/api/year-levels/', {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              });

              const semestersResponse = await axios.get('http://139.59.110.50/api/semesters/', {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              });

              const schoolYearsResponse = await axios.get('http://139.59.110.50/api/school-years/', {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              });

              // Set Year Levels, Semesters, and School Years with the new token
              setYearLevels(yearLevelsResponse.data);
              setSemesters(semestersResponse.data);
              setSchoolYears(schoolYearsResponse.data);
            } catch (retryError) {
              console.error('Error fetching data after refreshing access token:', retryError);
            }
          } else {
            console.error('Failed to refresh access token. Please log in again.');
          }
        } else {
          console.error('Error fetching year levels, semesters, or school years:', error);
        }
      }
    };

    fetchData();
  }, [selectedSchoolYear, selectedYearLevel, selectedSemester]);

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type !== 'text/csv') {
      alert('Only CSV files are allowed.');
      return;
    }
    setFormData({ ...formData, file });
  };
  

  const handleShowForm = () => setShowForm(true);
  const handleCloseForm = () => setShowForm(false);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
  
    // Prepare form data for API request
    const formDataToSend = new FormData();
    formDataToSend.append('courseCode', formData.courseCode);
    formDataToSend.append('courseTitle', formData.courseTitle);
    formDataToSend.append('section', formData.section);
    formDataToSend.append('schedule', formData.schedule);
    formDataToSend.append('schoolYear', formData.schoolYear);
    formDataToSend.append('yearLevel', formData.yearLevel);
    formDataToSend.append('semester', formData.semester);
    formDataToSend.append('file', formData.file); // Append the file
  
    try {
      // Send the request to the backend
      const response = await axios.post('http://139.59.110.50/api/create-classroom/', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data', // Necessary for file uploads
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });
  
      if (response.status === 201) {
        // The class was created successfully, so add to the classes state (if needed)
        const newClass = {
          id: Date.now(), // Use a unique ID or ID returned from the backend
          courseCode: formData.courseCode,
          courseTitle: formData.courseTitle,
          section: formData.section,
          schedule: formData.schedule,
          file: formData.file,
        };
  
        // Add the new class to the state
        setClasses([...classes, newClass]);
  
        // Reset form fields
        setFormData({
          courseCode: '',
          courseTitle: '',
          section: '',
          schedule: '',
          schoolYear: '',
          yearLevel: '',
          semester: '',
          file: null,
        });
  
        // Close the modal
        handleCloseForm();

        await fetchClassrooms();

      } else {
        console.error('Failed to create classroom:', response.data);
        // Optionally, you could show an error message to the user
      }
    } catch (error) {
      console.error('Error during class creation:', error);
      // Optionally, you could show an error message to the user
    }
  };  

  const handleCardClick = (classItem) => {
    console.log('Class clicked:', classItem);
    navigate('/classroom', { state: classItem }); // Navigate to the classroom page with class data
  };

  const handleDeleteClass = async () => {
    try {
        // Make the DELETE request to the API to remove the classroom
        const response = await fetch(`http://139.59.110.50/api/delete-classroom/${contextMenu.classId}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        });

        // Check if the deletion was successful
        if (response.ok) {
            // Remove the class with the id stored in the context menu
            const updatedClasses = classes.filter((classItem) => classItem.id !== contextMenu.classId);
            setClasses(updatedClasses);
            setContextMenu({ visible: false, x: 0, y: 0, classId: null }); // Hide the menu after delete
        } else {
            // Handle the error if deletion failed
            const errorData = await response.json();
            alert(`Failed to delete classroom: ${errorData.detail || 'Unknown error'}`);
        }
    } catch (error) {
        // Handle network or other unexpected errors
        console.error('Error deleting classroom:', error);
        alert('An error occurred while trying to delete the classroom.');
    }
  };

  const handleContextMenu = (e, classId) => {
    e.preventDefault();
    const cardBounds = e.target.getBoundingClientRect(); // Get the card's position
    setContextMenu({
      visible: true,
      x: cardBounds.right + 10, // Position beside the card (10px offset)
      y: cardBounds.top,       // Align with the card's top
      classId: classId,
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu({ visible: false, x: 0, y: 0, classId: null });
  };

  return (
    <div className="dashboard" onClick={handleCloseContextMenu}>
      
      {/* Filters and notification section */}
      <Stack direction="horizontal" gap={3} className="mb-4">

        {/* School Year Filter */}
        <Form.Select 
          aria-label="Select School Year"
          value={selectedSchoolYear}  // Bind the value to selectedSchoolYear
          onChange={(e) => setSelectedSchoolYear(e.target.value)}  // Update the state on change
        >
          <option value="">Select School Year</option>
          {schoolYears.map((year) => (
            <option key={year.id} value={year.id}>
              {year.school_year}  {/* Assuming "school_year" is the name of the school year */}
            </option>
          ))}
        </Form.Select>

        {/* Year Level Filter */}
        <Form.Select 
          aria-label="Select Year Level"
          value={selectedYearLevel}  // Bind the value to selectedYearLevel
          onChange={(e) => setSelectedYearLevel(e.target.value)}  // Update the state on change
        >
          <option value="">Select Year Level</option>
          {yearLevels.map((level) => (
            <option key={level.id} value={level.id}>
              {level.year_level}  {/* Assuming "year_level" is the name of the year level */}
            </option>
          ))}
        </Form.Select>

        {/* Semester Filter */}
        <Form.Select 
          aria-label="Select Semester"
          value={selectedSemester}  // Bind the value to selectedSemester
          onChange={(e) => setSelectedSemester(e.target.value)}  // Update the state on change
        >
          <option value="">Select Semester</option>
          {semesters.map((semester) => (
            <option key={semester.id} value={semester.id}>
              {semester.semester_name}  {/* Assuming "semester_name" is the name of the semester */}
            </option>
          ))}
        </Form.Select>

          {/* Notification */}
          <Notification />
      </Stack>

      {/* Class Cards Section */}
      <Row>
        {classes.map((classItem) => (
          <Col md={4} sm={6} xs={12} key={classItem.id} className="mb-4">
            <Card
              onClick={() => handleCardClick(classItem)}
              className="h-100"
              style={{
                backgroundColor: '#17A2B8',
                color: '#fff',
                cursor: 'pointer',
              }}
              onContextMenu={(e) => handleContextMenu(e, classItem.id)} // Open custom context menu
            >
              <Card.Body>
                <Card.Title>{classItem.course_code}</Card.Title>
                <Card.Subtitle>{classItem.course_title}</Card.Subtitle>
                <Card.Text>
                  <strong>Section:</strong> {classItem.section_code} <br />
                  <strong>Schedule:</strong> {classItem.schedule}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>


      {/* Floating Add Button */}
      <Button
        onClick={handleShowForm}
        className="btn btn-primary rounded-circle shadow"
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          width: '60px',
          height: '60px',
        }}
      >
        <BsPlus size={30} />
      </Button>

      {/* Class Creation Modal */}
      <Modal show={showForm} onHide={handleCloseForm} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {/* Form Fields */}
            <Form.Group controlId="formCourseCode" className="mb-3">
              <Form.Control
                type="text"
                name="courseCode"
                value={formData.courseCode}
                onChange={handleInputChange}
                placeholder="Enter course code"
                required
              />
            </Form.Group>

            <Form.Group controlId="formCourseTitle" className="mb-3">
              <Form.Control
                type="text"
                name="courseTitle"
                value={formData.courseTitle}
                onChange={handleInputChange}
                placeholder="Enter course title"
                required
              />
            </Form.Group>

            <Form.Group controlId="formSection" className="mb-3">
              <Form.Control
                type="text"
                name="section"
                value={formData.section}
                onChange={handleInputChange}
                placeholder="Enter section"
                required
              />
            </Form.Group>

            <Form.Group controlId="formSchedule" className="mb-3">
              <Form.Control
                type="text"
                name="schedule"
                value={formData.schedule}
                onChange={handleInputChange}
                placeholder="Enter schedule"
                required
              />
            </Form.Group>

            <Form.Group controlId="formSchoolYear" className="mb-3">
              <Form.Control
                type="text"
                name="schoolYear"
                value={formData.schoolYear}
                onChange={handleInputChange}
                placeholder="Enter school year"
                required
              />
            </Form.Group>

            <Form.Group controlId="formYearLevel" className="mb-3">
              <Form.Select
                name="yearLevel"
                value={formData.yearLevel}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Year Level</option>
                {yearLevels.map((level) => (
                  <option key={level.id} value={level.id}>
                    {level.year_level}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formSemester" className="mb-3">
              <Form.Select
                name="semester"
                value={formData.semester}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Semester</option>
                {semesters.map((semester) => (
                  <option key={semester.id} value={semester.id}>
                    {semester.semester_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            {/* File Upload inside Modal */}
            <Form.Group controlId="modalFileUpload" className="mb-3">
              <Form.Control
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                required
              />
            </Form.Group>

            <Button type="submit" variant="primary">
              Create Class
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Render the custom context menu if visible */}
      {contextMenu.visible && (
        <CustomContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          onDelete={handleDeleteClass}
          onClose={handleCloseContextMenu}
        />
      )}
    </div>
  );
}
