import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // React Router for navigation
import { Form, Button, InputGroup, ListGroup } from "react-bootstrap"; // React-Bootstrap components
import { FaSearch } from "react-icons/fa"; // Font Awesome Search Icon
import axios from "axios"; // Axios for API calls

export default function AttendanceRec() {
  const [searchQuery, setSearchQuery] = useState(""); // Search query state
  const [students, setStudents] = useState([]); // State to store student list
  const [loading, setLoading] = useState(true); // State to track loading status
  const [error, setError] = useState(null); // State to track any errors
  const navigate = useNavigate(); // React Router navigation

  // Fetch the list of students from the API
  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axios.get("http://139.59.110.50/api/students/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        setStudents(response.data); // Set students data
      } catch (err) {
        setError("Failed to fetch students. Please try again later.");
      } finally {
        setLoading(false); // Stop loading once the request is done
      }
    };

    fetchStudents();
  }, []);

  // Filter students based on the search query
  const filteredStudents = students.filter((student) =>
    `${student.ID_number} ${student.fname} ${student.surname}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container py-4">
      <h2 className="mb-4 text-center">Student List</h2>

      {/* Loading Indicator */}
      {loading && <p className="text-center">Loading students...</p>}

      {/* Error Message */}
      {error && <p className="text-center text-danger">{error}</p>}

      {/* Search Bar */}
      <InputGroup className="mb-3">
        <Form.Control
          type="text"
          placeholder="Search by ID number or name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Button variant="primary">
          <FaSearch />
        </Button>
      </InputGroup>

      {/* Student List */}
      <ListGroup>
        {filteredStudents.length > 0 ? (
          filteredStudents.map((student) => (
            <ListGroup.Item
              key={student.ID_number}
              action
              onClick={() =>
                navigate(`/records/${student.ID_number}`, {
                  state: {
                    studentName: `${student.fname} ${student.surname}`, // Pass student's name via state
                  },
                })
              } // Navigate to the Records page with student data
            >
              <strong>{student.ID_number}</strong> - {student.fname}{" "}
              {student.surname}
            </ListGroup.Item>
          ))
        ) : (
          <ListGroup.Item className="text-center text-muted">
            No students found
          </ListGroup.Item>
        )}
      </ListGroup>
    </div>
  );
}
