import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logo2 from '../logo/logo2.png';

function SideNavbar() {
  const [settingsVisible, setSettingsVisible] = useState(false); // State to toggle settings visibility
  const navigate = useNavigate();

  const handleSettingsToggle = () => {
    setSettingsVisible(!settingsVisible); // Toggle visibility of the settings options
  };

  return (
    <div 
      style={{
        width: '250px',
        height: '100vh',
        backgroundColor: '#383D3F', // Dark background for the sidebar
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px 0',
      }}
    >
      {/* Logo */}
      <div style={{ marginBottom: '50px' }}>
        <img 
          src={logo2} 
          alt="Logo" 
          style={{ width: '150px', height: 'auto' }} 
        />
      </div>

      {/* Navigation Links */}
      <Nav className="flex-column" style={{ width: '100%' }}>
        <Nav.Link 
          onClick={() => navigate('/dashboard')} 
          style={{ color: '#fff', padding: '10px 20px' }}
        >
          Dashboard
        </Nav.Link>

        {/* Settings */}
        <div>
          <Nav.Link
            onClick={handleSettingsToggle}
            style={{ color: '#fff', padding: '10px 20px', cursor: 'pointer' }}
          >
            Settings
          </Nav.Link>

          {/* Settings Dropdown (Only visible when settings are toggled) */}
          {settingsVisible && (
            <div style={{ marginLeft: '20px' }}>
              <Nav.Link 
                onClick={() => navigate('/user-info')} 
                style={{ color: '#fff', padding: '10px 20px' }}
              >
                User Information
              </Nav.Link>
              <Nav.Link 
                onClick={() => navigate('/reset-password')} 
                style={{ color: '#fff', padding: '10px 20px' }}
              >
                Reset Password
              </Nav.Link>
              <Nav.Link 
                onClick={() => navigate('/user-role')} 
                style={{ color: '#fff', padding: '10px 20px' }}
              >
                User Role
              </Nav.Link>
            </div>
          )}
        </div>

        {/* Attendance Record (No Navigation) */}
        <Nav.Link 
          onClick={() => navigate('/attendance-rec')} 
          style={{ color: '#fff', padding: '10px 20px', marginTop: 'auto' }} 
        >
          Attendance Record
        </Nav.Link>
        
        {/* Logout */}
        <Nav.Link 
          onClick={() => navigate('/Logout')} 
          style={{ color: '#fff', padding: '10px 20px', marginTop: 'auto' }} 
        >
          Logout
        </Nav.Link>
      </Nav>
    </div>
  );
}

export default SideNavbar;
