import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Table } from "react-bootstrap";

export default function Records() {
  const { id } = useParams(); // Get student ID from URL
  const { state } = useLocation(); // Get state from navigation
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStudentRecords = async () => {
      try {
        const response = await fetch(`http://139.59.110.50/api/student-records/${id}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Include JWT for authentication
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch records");
        }

        const data = await response.json();
        setRecords(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchStudentRecords();
  }, [id]);

  if (loading) {
    return <div className="text-center mt-4">Loading...</div>;
  }

  return (
    <div className="container py-4">
      {/* Display the student's name */}
      <h2 className="mb-4 text-center">
        {state?.studentName || "Student Attendance Records"}
      </h2>

      {records.length > 0 ? (
        records.map((record, index) => (
          <div key={index} className="mb-5">
            {/* Display Course Info with Late and Absent Counts */}
            <h4>
              {record.classroom} | <span>Late: {record.late_count}</span> | <span>Absent: {record.absent_count}</span>
            </h4>

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {record.attendance
                  .filter((attendance) => attendance.status) // Skip records with null or undefined status
                  .map((attendance, i) => (
                    <tr key={i}>
                      <td>{new Date(attendance.date).toLocaleDateString()}</td>
                      <td>{attendance.status}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        ))
      ) : (
        <div className="text-center text-muted">No attendance records found</div>
      )}
    </div>
  );
}
