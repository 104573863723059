import React from 'react';

const CustomContextMenu = ({ x, y, onDelete, onClose }) => {
  return (
    <div
      className="position-absolute bg-white border shadow rounded p-2"
      style={{
        top: y,
        left: x,
        minWidth: '100px',
        zIndex: 1050, // Ensure it floats above other elements
      }}
      onClick={onClose}
    >
      <button
        onClick={onDelete}
        className="btn btn-danger btn-sm w-100"
      >
        Delete
      </button>
    </div>
  );
};

export default CustomContextMenu;
