import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap'; // Import React Bootstrap components
import { FaCheckCircle } from 'react-icons/fa'; // Import check icon from react-icons
import { useNavigate } from "react-router-dom"; // Import useNavigate from React Router

export default function UpdatePassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [showNewPassword, setShowNewPassword] = useState(false); // State for new password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for confirm password visibility
  const [showAlert, setShowAlert] = useState(false); // State to show success alert
  const [errorMessage, setErrorMessage] = useState(''); // State to handle error messages

  const navigate = useNavigate();

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleNewPasswordVisibilityToggle = () => {
    setShowNewPassword(!showNewPassword); // Toggle visibility for new password
  };

  const handleConfirmPasswordVisibilityToggle = () => {
    setShowConfirmPassword(!showConfirmPassword); // Toggle visibility for confirm password
  };

  const handleResetPassword = async () => {
    if (newPassword === confirmPassword) {
      setPasswordMatch(true);
      setErrorMessage(''); // Clear previous errors

      try {
        const response = await fetch('http://139.59.110.50/api/update-password/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`, // Adjust token retrieval as needed
          },
          body: JSON.stringify({
            new_password: newPassword,
            confirm_password: confirmPassword,
          }),
        });

        if (response.ok) {
          // Password updated successfully
          setShowAlert(true);
          setTimeout(() => {
            navigate("/dashboard"); // Adjust the path as needed
          }, 2000); // Delay for the alert to be visible before navigating
        } else {
          // Handle errors from the backend
          const data = await response.json();
          setErrorMessage(data.detail || "An error occurred. Please try again.");
        }
      } catch (error) {
        setErrorMessage("Unable to connect to the server. Please try again later.");
      }
    } else {
      setPasswordMatch(false);
      setErrorMessage('Passwords do not match. Please try again.');
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={6}>
          {showAlert && (
            <Alert variant="success" className="mb-3">
              <FaCheckCircle className="mr-2" /> {/* Check icon */}
              Password updated successfully!
            </Alert>
          )}
          {errorMessage && (
            <Alert variant="danger" className="mb-3">
              {errorMessage}
            </Alert>
          )}
          <p className="mb-4">
            Please enter your new password and confirm it below. Ensure both passwords match before proceeding.
          </p>
          <Form>
            <Form.Group controlId="newPassword" className="mb-3">
              <Form.Control
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={handleNewPasswordChange}
                placeholder="New Password"
                required
              />
            </Form.Group>
            <Form.Group controlId="showNewPassword" className="mb-3">
              <Form.Check
                type="checkbox"
                label="Show New Password"
                onChange={handleNewPasswordVisibilityToggle}
              />
            </Form.Group>

            <Form.Group controlId="confirmPassword" className="mb-3">
              <Form.Control
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                placeholder="Confirm Password"
                required
              />
            </Form.Group>
            <Form.Group controlId="showConfirmPassword" className="mb-3">
              <Form.Check
                type="checkbox"
                label="Show Confirm Password"
                onChange={handleConfirmPasswordVisibilityToggle}
              />
            </Form.Group>

            {!passwordMatch && (
              <p className="text-danger">Passwords do not match. Please try again.</p>
            )}

            <Button onClick={handleResetPassword} variant="primary" className="w-100">
              Update password
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
