import React, { useState, useEffect } from 'react';
import { Form, Button, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Logo from "../logo/Logo.png";

function Registration() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  // State for form fields
  const [formData, setFormData] = useState({
    ID_number: '',
    fname: '',
    surname: '',
    email: '',
    department: '',
    password: '',
    confirmPassword: ''
  });

  // State for form select options
  const [roles, setRoles] = useState([]);
  const [colleges, setColleges] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedCollegeId, setSelectedCollegeId] = useState(null);

  // State for loading data
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [loadingColleges, setLoadingColleges] = useState(true);
  const [loadingDepartments, setLoadingDepartments] = useState(false);

  // State for error messages
  const [errorMessages, setErrorMessages] = useState([]);

  // Helper function to get CSRF token from cookies
  const getCSRFToken = () => {
    const csrfCookie = document.cookie.split('; ').find(row => row.startsWith('csrftoken='));
    return csrfCookie ? csrfCookie.split('=')[1] : '';
  };

  // Set CSRF token as a default header for all axios requests
  useEffect(() => {
    const csrfToken = getCSRFToken();
    if (csrfToken) {
      axios.defaults.headers.common['X-CSRFToken'] = csrfToken;
    }
  }, []);

  // Fetch roles, colleges, and departments from the backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [rolesResponse, collegesResponse] = await Promise.all([
          axios.get('http://139.59.110.50/api/roles/'),
          axios.get('http://139.59.110.50/api/colleges/'),
        ]);

        setRoles(rolesResponse.data.results || rolesResponse.data);
        setColleges(collegesResponse.data.results || collegesResponse.data);
        setLoadingRoles(false);
        setLoadingColleges(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoadingRoles(false);
        setLoadingColleges(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCollegeChange = (event) => {
    const selectedCollegeId = event.target.value;
    setSelectedCollegeId(selectedCollegeId);

    const fetchDepartments = async () => {
      setLoadingDepartments(true);
      try {
        const response = await axios.get(`http://139.59.110.50/api/departments/?college_id=${selectedCollegeId}`);
        setDepartments(response.data);
        setLoadingDepartments(false);
      } catch (error) {
        console.error('Error fetching departments:', error);
        setLoadingDepartments(false);
      }
    };

    if (selectedCollegeId) {
      fetchDepartments();
    } else {
      setDepartments([]);
      setLoadingDepartments(false);
    }
  };

  const handlePasswordToggle = () => setShowPassword(!showPassword);

  // Validation function
  const validateForm = () => {
    let errors = [];
    
    if (!formData.ID_number) {
      errors.push('ID Number is required');
    }
    if (!formData.fname) {
      errors.push('First Name is required');
    }
    if (!formData.surname) {
      errors.push('Last Name is required');
    }
    if (!formData.email) {
      errors.push('Email is required');
    }
    if (!formData.department) {
      errors.push('Department is required');
    }
    if (!formData.password) {
      errors.push('Password is required');
    }
    if (!formData.confirmPassword) {
      errors.push('Confirm Password is required');
    } else if (formData.password !== formData.confirmPassword) {
      errors.push('Passwords do not match');
    }

    setErrorMessages(errors);
    return errors.length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate form before submission
    if (!validateForm()) {
      return;
    }

    // Remove the college field from formData as it's used only to filter departments
    const { college, ...formDataWithoutCollege } = formData;

    try {
      const csrfToken = getCSRFToken();
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrfToken,
      };

      const response = await axios.post('http://139.59.110.50/api/register/', formDataWithoutCollege, { headers });
      console.log('Registration successful:', response);
      navigate('/activation-sent');
    } catch (error) {
      console.error('Error submitting form data:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <div style={{ width: '100%', maxWidth: '400px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginBottom: '40px', marginTop: '40px' }}>
          <img src={Logo} alt="Logo" style={{ width: '250px', height: '100px', marginBottom: '30px' }} />
          
          <Stack gap={3} style={{ width: '100%' }}>
            <Form.Group controlId="formIDNumber">
              <Form.Control 
                type="text" 
                placeholder="Enter ID Number" 
                required 
                name="ID_number" 
                value={formData.ID_number} 
                onChange={handleInputChange} 
                isInvalid={errorMessages.includes('ID Number is required')}
              />
              <Form.Control.Feedback type="invalid">
                ID Number is required
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formFirstName">
              <Form.Control 
                type="text" 
                placeholder="Enter First Name" 
                required 
                name="fname" 
                value={formData.fname} 
                onChange={handleInputChange} 
                isInvalid={errorMessages.includes('First Name is required')}
              />
              <Form.Control.Feedback type="invalid">
                First Name is required
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formLastName">
              <Form.Control 
                type="text" 
                placeholder="Enter Last Name" 
                required 
                name="surname" 
                value={formData.surname} 
                onChange={handleInputChange} 
                isInvalid={errorMessages.includes('Last Name is required')}
              />
              <Form.Control.Feedback type="invalid">
                Last Name is required
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Control 
                type="email" 
                placeholder="Enter Email Address" 
                required 
                name="email" 
                value={formData.email} 
                onChange={handleInputChange} 
                isInvalid={errorMessages.includes('Email is required') || errorMessages.includes('Email must be a university email (@ustp.edu.ph)')}
              />
              <Form.Control.Feedback type="invalid">
                {errorMessages.includes('Email is required')}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formCollege">
              <Form.Select 
                required 
                name="college" 
                value={formData.college} 
                onChange={event => { handleInputChange(event); handleCollegeChange(event); }} 
                isInvalid={errorMessages.includes('College is required')}
              >
                <option value="">Select College</option>
                {loadingColleges ? (
                  <option>Loading colleges...</option>
                ) : (
                  colleges.map((college) => <option key={college.id} value={college.id}>{college.collegeName}</option>)
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                College is required
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formDepartment">
              <Form.Select 
                required 
                disabled={!selectedCollegeId} 
                name="department" 
                value={formData.department} 
                onChange={handleInputChange} 
                isInvalid={errorMessages.includes('Department is required')}
              >
                <option value="">Select Department</option>
                {loadingDepartments ? (
                  <option>Loading departments...</option>
                ) : (
                  departments.map((department) => <option key={department.id} value={department.id}>{department.departmentName}</option>)
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Department is required
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formPassword">
              <Form.Control 
                type={showPassword ? 'text' : 'password'} 
                placeholder="Enter Password" 
                required 
                name="password" 
                value={formData.password} 
                onChange={handleInputChange} 
                isInvalid={errorMessages.includes('Password is required')}
              />
              <Form.Control.Feedback type="invalid">
                Password is required
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formConfirmPassword">
              <Form.Control 
                type={showPassword ? 'text' : 'password'} 
                placeholder="Confirm Password" 
                required 
                name="confirmPassword" 
                value={formData.confirmPassword} 
                onChange={handleInputChange} 
                isInvalid={errorMessages.includes('Confirm Password is required') || errorMessages.includes('Passwords do not match')}
              />
              <Form.Control.Feedback type="invalid">
                {errorMessages.includes('Confirm Password is required') ? 'Confirm Password is required' : 'Passwords do not match'}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formShowPassword">
              <Form.Check type="checkbox" label="Show Passwords" onChange={handlePasswordToggle} checked={showPassword} />
            </Form.Group>

            <Stack direction="horizontal" gap={2}>
              <Button variant="primary" type="submit" onClick={handleSubmit}>Register</Button>
              <Button variant="secondary" onClick={() => navigate('/')}>Back</Button>
            </Stack>
          </Stack>
        </div>
      </div>
    </div>
  );
}

export default Registration;
