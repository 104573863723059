import React from 'react';

function PageContainer({ children }) {
  return (
    <div 
      style={{
        flex: 1,
        padding: '20px',
        backgroundColor: '#f8f9fa', // Light gray background
        height: '100vh',
        overflowY: 'auto'
      }}
    >
      {children}
    </div>
  );
}

export default PageContainer;
