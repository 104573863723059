import React from "react";
import { Card } from "react-bootstrap";

function ActivationSent() {

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f8f9fa",
      }}
    >
        
      <Card
        style={{
          width: "400px",
          padding: "20px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
        }}
      >
        <Card.Body>
          <Card.Title className="text-center" style={{ marginBottom: "20px" }}>
            📩 Activation Link Sent!
          </Card.Title>
          <Card.Text style={{ textAlign: "center" }}>
            An activation link has been sent to your email. To complete your
            registration, please open your email and click the activation link.
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ActivationSent;
