import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/login';
import Registration from './pages/registration';
import Dashboard from './pages/dashboard';
import Layout from './DashboardLayout/layout';
import Classroom from './pages/classroom';
import ResetPassword from './pages/resetPassword'
import Logout from './pages/logout';
import UserInfo from './pages/userInfo';
import UpdatePassword from './pages/updatePassword';
import UserRole from './pages/userRole'
import AttendanceRec from './pages/attendanceRec';
import Records from './pages/records';
import ActivationLogin from './pages/activationLogin';
import ActivationSent from './pages/activationSent';
import ProtectedRoute from './utils/ProtectedRoute';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes (No Layout) */}
        <Route path="/" element={<Login />} />
        <Route path="/activation-sent" element={<ActivationSent />} />
        <Route path="/activation-login" element={<ActivationLogin />} />
        <Route path="/register" element={<Registration />} />

        {/* Protected Route (With Layout) */}
        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<Layout><Dashboard /></Layout>} />
          <Route path="/classroom" element={<Layout><Classroom /></Layout>} />
          <Route path="/reset-password" element={<Layout><ResetPassword /></Layout>} />
          <Route path="/logout" element={<Layout><Logout /></Layout>} />
          <Route path="/user-info" element={<Layout><UserInfo /></Layout>} />
          <Route path="/update-password" element={<Layout><UpdatePassword /></Layout>} />
          <Route path="/user-role" element={<Layout><UserRole /></Layout>} />
          <Route path="/attendance-rec" element={<Layout><AttendanceRec /></Layout>} />
          <Route path="/records/:id" element={<Layout><Records /></Layout>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
