import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Container, Button, Alert } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa'; // Import check icon from react-icons
import { useNavigate } from 'react-router-dom'; // Import useNavigate from React Router

export default function UserInfo() {
  const [userInfo, setUserInfo] = useState({
    ID_number: '',
    fname: '',
    surname: '',
    email: '',
    college: '',
    department: ''
  });

  const [colleges, setColleges] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [showAlert, setShowAlert] = useState(false); // State to show success alert

  const navigate = useNavigate();

  // Function to refresh access token
  const refreshAccessToken = async () => {
    try {
      const refreshToken = localStorage.getItem('refresh_token');
      const response = await fetch('http://139.59.110.50/api/auth/jwt/refresh/', {
        method: 'POST',
        body: JSON.stringify({ refresh: refreshToken }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('access_token', data.access); // Save the new access token
        return data.access;
      } else {
        console.error('Failed to refresh access token');
        return null;
      }
    } catch (error) {
      console.error('Error refreshing access token:', error);
      return null;
    }
  };

  // Fetch user info and available colleges when component mounts
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        let accessToken = localStorage.getItem('access_token');
        // Check if the access token is expired
        const isExpired = false; // Replace with actual token expiration check logic
        if (isExpired) {
          accessToken = await refreshAccessToken(); // Refresh the access token if expired
        }

        if (accessToken) {
          const response = await fetch('http://139.59.110.50/api/user-info/', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${accessToken}` // Assuming token is stored in localStorage
            }
          });
          if (response.ok) {
            const data = await response.json();
            setUserInfo({
              ID_number: data.ID_number,
              fname: data.fname,
              surname: data.surname,
              email: data.email,
              college: data.college,
              department: data.department
            });
          } else {
            console.error('Error fetching user info');
          }
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    const fetchColleges = async () => {
      try {
        let accessToken = localStorage.getItem('access_token');
        const isExpired = false; // Replace with actual token expiration check logic
        if (isExpired) {
          accessToken = await refreshAccessToken(); // Refresh the access token if expired
        }

        if (accessToken) {
          const response = await fetch('http://139.59.110.50/api/colleges/', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          });
          if (response.ok) {
            const data = await response.json();
            setColleges(data); // Assuming response is an array of colleges
          } else {
            console.error('Error fetching colleges');
          }
        }
      } catch (error) {
        console.error('Error fetching colleges:', error);
      }
    };

    fetchUserInfo();
    fetchColleges();
  }, []);

  // Fetch departments when college is selected
  const fetchDepartments = async (collegeId) => {
    try {
      let accessToken = localStorage.getItem('access_token');
      const isExpired = false; // Replace with actual token expiration check logic
      if (isExpired) {
        accessToken = await refreshAccessToken(); // Refresh the access token if expired
      }

      if (accessToken) {
        const response = await fetch(`http://139.59.110.50/api/departments/?college_id=${collegeId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setDepartments(data); // Assuming response is an array of departments
        } else {
          console.error('Error fetching departments');
        }
      }
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };

  // Fetch departments when college is selected
  useEffect(() => {
    if (userInfo.college) {
      fetchDepartments(userInfo.college);
    }
  }, [userInfo.college]);

  // Handle form field change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({
      ...userInfo,
      [name]: value
    });
  };

  // Handle the form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      let accessToken = localStorage.getItem('access_token');
      const isExpired = false; // Replace with actual token expiration check logic
      if (isExpired) {
        accessToken = await refreshAccessToken(); // Refresh the access token if expired
      }

      if (accessToken) {
        const response = await fetch('http://139.59.110.50/api/user-update/', {
          method: 'PUT',
          body: JSON.stringify(userInfo),  // Sending updated user data
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`  // Attach the token
          }
        });
    
        if (response.ok) {
          const updatedData = await response.json();  // Get the updated user data from response
          setUserInfo(updatedData);  // Update the local state with the new data
          setShowAlert(true); // Show success alert
          setTimeout(() => {
            navigate('/dashboard'); // Redirect to the dashboard after 1.5 seconds
          }, 1500);
        } else {
          const errorData = await response.json();
          console.error('Failed to update user information', errorData);
          alert('Failed to update user information');
        }
      }
    } catch (error) {
      console.error('Error updating user info:', error);
      alert('Error updating user information');
    }
  };

  return (
    <Container fluid className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <Row className="w-100">
        <Col md={6} sm={8} xs={12} className="mx-auto">
          <h2 className="text-center mb-4">User Information</h2>

          {showAlert && (
            <Alert variant="success" className="mb-3">
              <FaCheckCircle className="mr-2" />
              User information updated successfully!
            </Alert>
          )}

          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formIDNumber" className="mb-3">
              <Form.Label>ID Number</Form.Label>
              <Form.Control 
                type="text" 
                name="id_number" 
                value={userInfo.ID_number} 
                onChange={handleInputChange} 
                placeholder="Enter ID Number" 
              />
            </Form.Group>

            <Form.Group controlId="formFirstName" className="mb-3">
              <Form.Label>Firstname</Form.Label>
              <Form.Control 
                type="text" 
                name="fname" 
                value={userInfo.fname} 
                onChange={handleInputChange} 
                placeholder="Enter Firstname" 
              />
            </Form.Group>

            <Form.Group controlId="formLastName" className="mb-3">
              <Form.Label>Lastname</Form.Label>
              <Form.Control 
                type="text" 
                name="surname" 
                value={userInfo.surname} 
                onChange={handleInputChange} 
                placeholder="Enter Lastname" 
              />
            </Form.Group>

            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control 
                type="email" 
                name="email" 
                value={userInfo.email} 
                onChange={handleInputChange} 
                placeholder="Enter Email" 
              />
            </Form.Group>

            <Form.Group controlId="formCollege" className="mb-3">
              <Form.Label>College</Form.Label>
              <Form.Select 
                name="college" 
                value={userInfo.college} 
                onChange={handleInputChange}
              >
                <option value="">Select College</option>
                {colleges.map(college => (
                  <option key={college.id} value={college.id}>
                    {college.collegeName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formDepartment" className="mb-3">
              <Form.Label>Department</Form.Label>
              <Form.Select 
                name="department" 
                value={userInfo.department} 
                onChange={handleInputChange}
              >
                <option value="">Select Department</option>
                {departments.map(department => (
                  <option key={department.id} value={department.id}>
                    {department.departmentName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100 mt-4">
              Update
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
