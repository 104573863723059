import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap'; // Import React Bootstrap components
import { useNavigate } from "react-router-dom"; // Import useNavigate from React Router

export default function UpdateRole() {
  const [selectedRole, setSelectedRole] = useState('');
  const [roles, setRoles] = useState([]); // To store roles fetched from the backend
  const [showAlert, setShowAlert] = useState(false); // To manage alert visibility
  const [errorMessage, setErrorMessage] = useState(''); // To handle error messages
  const navigate = useNavigate();

  // Function to refresh the access token using the refresh token
  const refreshAccessToken = async () => {
    try {
      const refreshToken = localStorage.getItem('refresh_token');
      if (!refreshToken) throw new Error('No refresh token available');

      const response = await fetch('http://139.59.110.50/api/auth/jwt/refresh/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh: refreshToken }),
      });

      if (!response.ok) {
        throw new Error('Failed to refresh token');
      }

      const data = await response.json();
      localStorage.setItem('access_token', data.access); // Store the new access token
      return data.access;
    } catch (error) {
      setErrorMessage('Error refreshing token: ' + error.message);
      return null;
    }
  };

  // Fetching roles from the backend
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        let accessToken = localStorage.getItem('access_token');
        
        // Check if the token is expired and refresh it if necessary
        if (isTokenExpired(accessToken)) {
          accessToken = await refreshAccessToken();
          if (!accessToken) return; // If the token refresh failed, exit early
        }

        const response = await fetch('http://139.59.110.50/api/roles/', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch roles');
        }

        const data = await response.json();
        setRoles(data); // Set roles state with the fetched data
      } catch (error) {
        setErrorMessage('Error fetching roles: ' + error.message);
      }
    };

    fetchRoles();
  }, []); // Empty dependency array means this runs only once when the component mounts

  // Check if the token is expired (you can modify this check based on your token's expiration logic)
  const isTokenExpired = (token) => {
    if (!token) return true;
    const decoded = JSON.parse(atob(token.split('.')[1]));
    return decoded.exp < Date.now() / 1000;
  };

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleRoleUpdate = async () => {
    if (!selectedRole) {
      setErrorMessage('Please select a role.');
      return;
    }

    try {
      let accessToken = localStorage.getItem('access_token');
      
      // Check if the token is expired and refresh it if necessary
      if (isTokenExpired(accessToken)) {
        accessToken = await refreshAccessToken();
        if (!accessToken) return; // If the token refresh failed, exit early
      }

      const response = await fetch('http://139.59.110.50/api/update-role/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`, // Use the updated access token
        },
        body: JSON.stringify({
          role: selectedRole,
        }),
      });

      if (!response.ok) {
        throw new Error('Your role request is still pending');
      }

      // Simulating successful role update request
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        navigate("/dashboard"); // Redirect after successful role update request
      }, 1900); // Display the alert for 1.5 seconds
    } catch (error) {
      setErrorMessage('Error updating role: ' + error.message);
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <p className="mb-4">
            Please note that it may take 2-3 business days for the admin to approve your role update request.
          </p>

          {showAlert && (
            <Alert variant="success" className="mb-3">
              Request sent. Please wait 2-3 business days for admin approval.
            </Alert>
          )}

          {errorMessage && (
            <Alert variant="warning" className="mb-3">
              {errorMessage}
            </Alert>
          )}

          <Form>
            <Form.Group controlId="roleSelect" className="mb-3">
              <Form.Select as="select" value={selectedRole} onChange={handleRoleChange} required>
                <option value="">Select a role...</option>
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.roleTitle}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Button onClick={handleRoleUpdate} variant="primary" className="w-100">
              Update Role
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
