import React, { useState } from 'react';
import { Form, Button, Stack, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for API requests
import Logo from "../logo/Logo.png";

function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');  
  const [password, setPassword] = useState('');  
  const [errorMessage, setErrorMessage] = useState(''); 

  const handlePasswordToggle = () => setShowPassword(!showPassword);

  const handleLogin = async (e) => {
    e.preventDefault();  // Prevent default form submission behavior

    const loginData = { email, password };
    console.log("Login Data Sent:", loginData);

    try {
      // Sending POST request to the server
      const response = await axios.post('http://139.59.110.50/api/auth/faculty-login/', loginData);

      // Destructuring the access and refresh tokens from the response
      const { access, refresh } = response.data;

      // Store the tokens in localStorage
      localStorage.setItem('access_token', access);
      localStorage.setItem('refresh_token', refresh);

      // Optionally, you could set this as a default header for axios requests
      // This way, the access token is included in the Authorization header for future requests
      axios.defaults.headers.common['Authorization'] = `Bearer ${access}`;

      // Navigate to the dashboard after a successful login
      navigate('/dashboard');
      
    } catch (error) {
      // Handle errors gracefully
      if (error.response) {
        // If the error has a response (server error)
        setErrorMessage(error.response.data.detail || 'Login failed. Please check your credentials.');
      } else if (error.request) {
        // If no response was received from the server
        setErrorMessage('No response from server. Please try again later.');
      } else {
        // Any other unexpected errors
        setErrorMessage('An unexpected error occurred. Please try again.');
      }
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <div style={{ width: '100%', maxWidth: '400px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginBottom: '40px',
            marginTop: '40px',
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            style={{
              width: '250px',
              height: '100px',
              marginBottom: '100px',
            }}
          />

          <Stack gap={3} style={{ width: '100%' }}>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

            <Form.Group controlId="formEmail">
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formPassword">
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formShowPassword" style={{ marginTop: '-10px' }}>
              <Form.Check 
                type="checkbox" 
                label="Show password" 
                onChange={handlePasswordToggle} 
                checked={showPassword}
              />
            </Form.Group>

            <Stack direction="horizontal" gap={2}>
              <Button 
                variant="primary" 
                style={{ flex: 1 }} 
                onClick={handleLogin}
              >
                Login
              </Button>
              <Button 
                variant="secondary" 
                style={{ flex: 1 }} 
                onClick={() => navigate('/register')}
              >
                Register
              </Button>
            </Stack>
          </Stack>
        </div>
      </div>
    </div>
  );
}

export default Login;
