import { useState, useEffect } from "react";
import { Dropdown, Badge, Spinner } from "react-bootstrap"; // Bootstrap Components
import { Bell } from "react-bootstrap-icons"; // Bootstrap Bell Icon
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchNotifications = async () => {
    try {
      const response = await axios.get("http://139.59.110.50/api/notifications/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setNotifications(response.data.notifications || []);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
    }
  };

  const markAsRead = async (id) => {
    try {
      await axios.patch(`http://localhost:8000/api/notifications/${id}/mark-as-read/`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setNotifications((prev) =>
        prev.map((notif) => (notif.id === id ? { ...notif, is_read: true } : notif))
      );
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const handleNotificationClick = (notif) => {
    console.log("Notification clicked:", notif); // Debugging line to inspect notif object
    markAsRead(notif.id);
  
    // Extract the student ID from the message using a regular expression
    const match = notif.message.match(/Student (\d+)/);  // Matches "Student <ID_number>"
  
    // Ensure we have a valid match
    if (match && match[1]) {
      const studentId = match[1]; // Extracted ID number
      const studentName = notif.student_name || "Unknown Student"; // Ensure student_name exists
  
      // Use the studentId to navigate
      navigate(`/records/${studentId}`, {
        state: { studentName: studentName },
      });
    } else {
      console.error("Failed to extract student ID from message:", notif.message);
      navigate(`/records/unknown`, {
        state: { studentName: notif.student_name },
      });
    }
  };  

  useEffect(() => {
    fetchNotifications();
  }, []);

  const unreadCount = notifications.filter((n) => !n.is_read).length;

  return (
    <Dropdown align="end">
      <Dropdown.Toggle variant="light" id="dropdown-basic" className="position-relative">
        <Bell size={22} />
        {unreadCount > 0 && (
          <Badge bg="danger" pill className="position-absolute top-0 start-100 translate-middle">
            {unreadCount}
          </Badge>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className="notification-dropdown overflow-auto" style={{ width: "450px", maxHeight: "350px" }}>
        <Dropdown.Header>Notifications</Dropdown.Header>

        {loading ? (
          <Dropdown.Item className="text-center">
            <Spinner animation="border" size="sm" /> Loading...
          </Dropdown.Item>
        ) : notifications.length === 0 ? (
          <Dropdown.Item className="text-muted text-center">No new notifications</Dropdown.Item>
        ) : (
          notifications.map((notif) => (
            <Dropdown.Item
              key={notif.id}
              onClick={() => handleNotificationClick(notif)}
              className={notif.is_read ? "text-muted" : "fw-bold"}
              style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {notif.message}
              {!notif.is_read && <Badge bg="primary" className="ms-2">New</Badge>}
            </Dropdown.Item>
          ))
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Notification;
