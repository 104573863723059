import React from 'react';
import SideNavbar from './sideNavbar';
import PageContainer from './pageContainer';

function Layout({ children }) {
  return (
    <div 
      style={{
        display: 'flex',
        height: '100vh',
      }}
    >
      {/* Side Navbar */}
      <SideNavbar />

      {/* Main Page Content */}
      <PageContainer>
        {children}
      </PageContainer>
    </div>
  );
}

export default Layout;
