import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from 'react-bootstrap'; // Import React Bootstrap components
import { useNavigate } from "react-router-dom"; // Import useNavigate from React Router

export default function ResetPassword() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate hook

  // Update the password value when user types
  const handlePasswordChange = (e) => {
    setCurrentPassword(e.target.value);
  };

  // Toggle the visibility of the password field
  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  // Handle the password verification process
  const handleProceed = async () => {
    try {
      // Retrieve the access token from localStorage or any other storage method
      const accessToken = localStorage.getItem("access_token");
      
      if (!accessToken) {
        alert("You need to be logged in to perform this action.");
        return;
      }

      // Make the API request to verify the current password
      const response = await fetch('http://139.59.110.50/api/verify-current-password/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`  // Attach the access token
        },
        body: JSON.stringify({ current_password: currentPassword })
      });

      // Check if the response is successful
      if (response.ok) {
        // If password is verified, navigate to the password update page
        navigate("/update-password");
      } else {
        const data = await response.json();
        alert(data.detail);  // Show the error message from the response
      }
    } catch (error) {
      console.error("Error verifying current password:", error);
      alert("An error occurred while verifying your current password.");
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <p className="mb-4">
            To protect your account from unauthorized access and to prevent potential security risks, please enter your current password before proceeding with the change. This step is essential to ensure the integrity of your account and to avoid any unintended account hijacking.
          </p>
          <Form>
            <Form.Group controlId="currentPassword" className="mb-3">
              <Form.Control
                type={showPassword ? "text" : "password"}
                value={currentPassword}
                onChange={handlePasswordChange}
                placeholder="Current Password"
                required
              />
            </Form.Group>
            <Form.Group controlId="showPassword" className="mb-3">
              <Form.Check
                type="checkbox"
                label="Show Password"
                onChange={handleShowPasswordToggle}
              />
            </Form.Group>
            <Button onClick={handleProceed} variant="primary" className="w-100">
              Proceed
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
