import React, { useRef, useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Webcam from "react-webcam";
import axios from "axios";

function FaceRecognitionButton() {
  const webcamRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [recognizedId, setRecognizedId] = useState(""); // Stores recognized ID
  const [isProcessing, setIsProcessing] = useState(false); // Prevents multiple requests

  useEffect(() => {
    if (showModal) {
      startFaceRecognition();
    } else {
      stopFaceRecognition();
    }

    return () => stopFaceRecognition(); // Cleanup on unmount
  }, [showModal]);

  // Capture and process frames continuously
  const startFaceRecognition = () => {
    setRecognizedId(""); // Reset ID when opening modal
    setIsProcessing(false);
    requestAnimationFrame(captureAndSendImage); // Start frame processing
  };

  // Stop capturing frames
  const stopFaceRecognition = () => {
    setIsProcessing(false);
    setRecognizedId(""); // Reset ID when modal closes
  };

  // Capture frame and send to backend if a face is detected
  const captureAndSendImage = async () => {
    if (!webcamRef.current || isProcessing) return;

    setIsProcessing(true); // Prevent overlapping requests

    const canvas = webcamRef.current.getCanvas();
    if (!canvas) {
      setIsProcessing(false);
      requestAnimationFrame(captureAndSendImage);
      return;
    }

    // Convert canvas to Blob (raw image data)
    canvas.toBlob(async (blob) => {
      const formData = new FormData();
      formData.append("image", blob, "face.jpg");  // ✅ Removed classroom_id

      try {
        const response = await axios.post(
          "http://139.59.110.50/api/recognize-face/",
          formData,
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` },
          }
        );

        if (response.status === 200 && response.data.student_id) {
          setRecognizedId(response.data.student_id); // ✅ Update ID number
        } else {
          setRecognizedId(""); // ❌ No match found
        }
      } catch (error) {
        console.error("🚨 Recognition Error:", error);
        setRecognizedId(""); // ❌ No match found
      }

      setIsProcessing(false);
      requestAnimationFrame(captureAndSendImage); // Continue capturing
    }, "image/jpeg");
  };

  return (
    <>
      <Button variant="success" onClick={() => setShowModal(true)}>
        Mark via Face Recognition
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Face Recognition</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {/* ✅ Webcam always ON */}
          <Webcam
            ref={webcamRef}
            audio={false}
            screenshotFormat="image/jpeg"
            width="100%"
            height="auto"
            videoConstraints={{ facingMode: "user" }}
            style={{ borderRadius: "10px", background: "#000" }}
          />

          {/* ✅ ID Number appears below the webcam */}
          <p className="mt-3">
            <strong>ID Number: </strong> {recognizedId || " "}
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FaceRecognitionButton;
